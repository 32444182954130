import React, { useEffect } from "react";
import Initiatives from "../components/initiatives/initiatives";
import Purpose from "../components/purpose/purpose";
import Aos from "aos";

const Ourinitiatives = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Initiatives />
      <Purpose />
    </>
  );
};

export default Ourinitiatives;
