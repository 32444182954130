import React from "react";
import icon from "../../assets/homepage/top container.webp";
const Hometopcontainer = () => {
  return (
    <div id="home" className="bg-yellow-200 w-full h-[100%] flex mb-[50px] flex-wrap">
      <div className="w-[30%] mb-[20px] flex-1 text-center pl-[30px] pt-[30px] flex flex-col gap-[30px] justify-center items-center max-[962px]:w-[80%] max-[962px]:pt-[40px] max-[962px]:pl-0 max-[500px]:px-[15px] ">
        <span className="font-bold text-[35px] text-[#7AAB00] max-[962px]:w-[60%] max-[850px]:text-[30px] max-[550px]:w-[80%] max-[500px]:w-[90%] max-[400px]:w-[90%]">
          अपने गाँव में खोले{" "}
          <span className="text-[#69564C]">पशुसेवक प्रगति केंद्र </span>पशुपालन
          को बढ़ावा दे और अपने गांव को <span className="text-[#69564C]">प्रगति</span>{" "}
          के पथ पर ले चले
          <span className="text-[#69564C]">...</span>
        </span>
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSfCo03snaTFTpUC6S3oi-L1nCKTVwoKGvTub4Ztv608Q0iBVQ/viewform?usp=sf_link">
          <button className="border text-[24px] border-solid rounded-[10px] py-[5px] px-[10px] text-white font-extrabold bg-[#69564C] hover:bg-[#695000]">
            <span className="text-[#FDDC59]"> अभी आवेदन करें</span>
          </button>
        </a>
      </div>
      <img src={icon} alt="" className="w-[70%] flex-1 max-[962px]:w-[100%]" />
    </div>
  );
};

export default Hometopcontainer;
