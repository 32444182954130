import React from "react";
import icon from "../../assets/purpose/b-2.png";
const Purpose = () => {
  return (
    <div className="relative w-full h-[135vh] mt-[8vh] max-[1500px]:h-[145vh] max-[1376px]:h-[149vh] max-[1200px]:h-[155vh] max-[1048px]:h-[160vh] max-[743px]:h-[175vh] max-[625px]:h-auto">
      <div className=" w-full mb-[4%] text-center text-[35px] text-[#FDDC59] max-[920px]:text-[30px] max-[800px]:text-[26px] max-[750px]:text-[23px] max-[658px]:text-[20px] max-[580px]:text-[17px] max-[500px]:text-[15px] max-[450px]:text-[13px]">
        <span className="ring-2 ring-[#69564C] ring-offset-1 bg-[#69564C] rounded-[10px] p-[10px] px-[20px] font-[600]">
          पशुसेवक प्रगति केंद्रों के संचालन के पीछे हमारा उद्देश्य
        </span>
      </div>
      <div class="overflow-hidden relative flex justify-center items-center h-[105vh] text-[#617926] font-bold max-[625px]:my-[50px] max-[1500px]:h-[110vh] max-[1376px]:h-[120vh] max-[1200px]:h-[130vh] max-[1048px]:h-[135vh] max-[937px]:h-[139vh] max-[793px]:h-[145vh] max-[743px]:h-[155vh] max-[625px]:h-auto max-[625px]:flex-col max-[625px]:gap-[25px] max-[625px]:py-[20px]">
        <div class="flex w-full justify-between mx-[5%] max-[953px]:mx-[3%] max-[625px]:flex-col max-[625px]:items-center max-[625px]:gap-[25px] max-[625px]:justify-center">
          <div
            data-aos="fade-right"
            data-aos-once="true"
            data-aos-offset="200"
            className="shadow-xl w-[30%] ring-2 ring-offset-1 ring-[#69564C] border-4 border-solid border-[#69564C] rounded-[10px] p-[10px] text-[21px] max-[1256px]:text-[20px] max-[1048px]:text-[19px] max-[849px]:text-[20px] max-[750px]:w-[32%] max-[670px]:w-[34%] max-[625px]:w-[70%] max-[484px]:w-[80%] max-[700px]:text-[19px] max-[500px]:text-[15px]"
          >
            <span className="text-center">
              हम अपने पशुसेवक प्रगति केंद्रों पर शिविरों और स्मार्ट टीवी का
              आयोजन करके अपने साथ जुड़े विभिन्न डॉक्टरों और शोधकर्ताओं के
              व्याख्यानों के माध्यम से पशुसेवकों को पशुओं में एफएमडी, लम्पी आदि
              जैसी विभिन्न बीमारियों और उनकी रोकथाम के बारे में शिक्षित/जागरूक
              करेंगे।
            </span>
          </div>
          <div
            data-aos="fade-left"
            data-aos-once="true"
            data-aos-offset="200"
            className="shadow-xl w-[30%] ring-2 ring-offset-1 ring-[#69564C] border-4 border-solid border-[#69564C] rounded-[10px] p-[10px] text-[21px] max-[1256px]:text-[20px] max-[1048px]:text-[19px] max-[849px]:text-[20px] max-[750px]:w-[32%] max-[670px]:w-[34%] max-[625px]:w-[70%] max-[484px]:w-[80%] max-[700px]:text-[19px] max-[500px]:text-[18px] max-[500px]:text-[16px]"
          >
            <span className="text-center">
              हम विभिन्न संगठनों के सहयोग के द्वारा जो पशुसेवकों को हमारे
              पशुसेवक प्रगति केंद्र और हमारे पशुसेवक प्रगति वाहन के माध्यम से
              पशुपालन विभाग द्वारा प्रदान की जाने वाली विभिन्न अनुदान/सब्सिडी और
              योजनाओं के बारे में शिक्षित, मार्गदर्शन और सुविधा प्रदान करेंगे।
            </span>
          </div>
        </div>
        <div class="absolute flex flex-col h-[95%] w-1/2 justify-between text-[#617926] max-[1048px]:h-[95%] max-[900px]:w-[60%] max-[625px]:relative max-[625px]:justify-center max-[625px]:gap-[25px] max-[625px]:w-[70%] max-[484px]:w-[80%]">
          <div
            data-aos="fade-down"
            data-aos-once="true"
            data-aos-offset="200"
            className="shadow-xl ring-2 ring-offset-1 ring-[#69564C] border-4 border-solid border-[#69564C] rounded-[10px] p-[10px] text-[21px] max-[1048px]:text-[19px] max-[849px]:text-[20px] max-[700px]:text-[19px] max-[500px]:text-[16px] max-[400px]:text-[15px]"
          >
            <span className="text-center">
              हम पशुसेवकों को उनके गांवों में हमारे पशुसेवक प्रगति केंद्रों पर
              उनके पशुओं के लिए कुछ बीमारियों के इलाज और उनकी रोग प्रतिरोधक
              क्षमता के साथ-साथ उत्पादकता आदि में सुधार के लिए पोषक पशु आहार
              प्रदान करेंगे।
            </span>
          </div>
          <div
            data-aos="fade-up"
            data-aos-once="true"
            data-aos-offset="200"
            className="shadow-xl ring-2 ring-offset-1 ring-[#69564C] border-4 border-solid border-[#69564C] rounded-[10px] p-[10px] text-[21px] max-[1048px]:text-[19px] max-[849px]:text-[20px] max-[700px]:text-[19px] max-[500px]:text-[16px] max-[400px]:text-[15px]"
          >
            <span className="text-center">
              हम विभिन्न संगठनों के सहयोग के द्वारा जो गांवों के पशुपालको को
              उनके उत्पादों जैसे घी, डेयरी उत्पादों आदि के निर्माण, ब्रांडिंग और
              मार्केटिंग में शिक्षित, मार्गदर्शन और सुविधा प्रदान करेंगे। हम
              अपने केंद्रों पर पशुपालको को उनके उत्पाद बेचकर प्रोत्साहित भी
              करेंगे।
            </span>
          </div>
        </div>
      </div>
      <div className="z-5 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white w-[300px] h-[300px] max-[840px]:w-[250px] max-[840px]:h-[250px] max-[750px]:w-[210px] max-[750px]:h-[210px] max-[670px]:w-[175px] max-[670px]:h-[175px] max-[625px]:hidden flex justify-center items-center rounded-[50%] border-8 border-solid border-[#69564C]">
        <img
          src={icon}
          alt=""
          className="w-[200px] h-[200px] max-[840px]:w-[170px] max-[840px]:h-[170px] max-[750px]:w-[140px] max-[750px]:h-[140px] max-[670px]:h-[115px] max-[670px]:w-[115px]"
        />
      </div>
    </div>
  );
};

export default Purpose;
// <div className="relative h-[100%] flex justify-center items-center">
//   <div className="flex justify-between text-[19px] font-bold items-center mx-[5vw]"></div>
//   <div className="absolute left-1/2 -translate-x-[50%] flex flex-col h-[90vh] justify-between text-[19px] font-bold"></div>
// </div>
