import React from "react";
import icon from "../../assets/mission/image.png";
const Mission = () => {
  return (
    <>
      <div id="aboutus" className="bg-gradient-to-r from-[#FDDC59] to-white pt-[4%] max-[560px]:bg-none max-[1000px]:pt-[2%] max-[700px]:pt-[4%] max-[450px]:pt-[7%]">
        <div className=" w-full text-center text-[35px] text-[#FDDC59] max-[800px]:text-[30px] max-[680px]:text-[28px] max-[570px]:text-[23px] max-[460px]:text-[22px] max-[1000px]:my-[4%] max-[600px]:mb-[8%]">
          <span className="ring-2 ring-[#69564C] ring-offset-1 bg-[#69564C] rounded-[10px] p-[10px] px-[20px] font-[600]">
            हमारा उद्देश्य
          </span>
        </div>
        <div className="py-[20px] flex mb-[5%] max-[560px]:justify-center max-[560px]:shadow-none">
          <div className="w-[45vw] flex flex-col justify-center items-center gap-[60px] mx-[60px] text-[#617926] font-bold max-[950px]:w-[36vw] max-[850px]:w-[34vw] max-[704px]:gap-[40px] max-[650px]:w-[31vw]">
            <div
              data-aos="fade-right"
              data-aos-once="true"
              data-aos-offset="200"
              className="shadow-2xl ring-2  ring-offset-1 ring-[#69564C] rounded-[20px] w-[25.625rem] border-4 border-solid border-[#69564C] text-[25px] flex justify-center items-center p-[10px] max-[1000px]:w-[23rem] max-[850px]:w-[22rem] max-[850px]:text-[23px] max-[750px]:text-[21px] max-[750px]:w-[21rem] max-[703px]:w-[19rem] max-[703px]:text-[20px] max-[675px]:w-[18rem] max-[640px]:w-[17rem]"
            >
              <div className="text-center">
                ग्रामीण क्षेत्र/गांवों में पशुपालन को बढ़ावा देना, जो 
                दिन प्रतिदिन
                कम होता जा रहा है
              </div>
            </div>
            <div
              data-aos="fade-right"
              data-aos-once="true"
              data-aos-offset="200"
              className="ring-2 shadow-2xl ring-offset-1 ring-[#69564C] rounded-[20px] w-[25.625rem] text-center border-4 border-solid border-[#69564C] text-[23px] flex justify-center items-center p-[10px] max-[1000px]:w-[23rem] max-[850px]:w-[22rem] max-[750px]:text-[21px] max-[750px]:w-[21rem] max-[703px]:w-[19rem] max-[703px]:text-[20px] max-[675px]:text-[19px] max-[675px]:w-[18rem] max-[600px]:w-[17rem] max-[640px]:text-[18px]"
            >
              हमारे सहयोगी संगठन के माध्यम से पशुसेवको/पशुपालको को
              शिक्षित, मार्गदर्शन और सुविधा प्रदान करना ताकि उनके गांवों में
              लाभदायक व्यवसाय स्थापित और विकसित किए जा सकें और विश्व स्तर पर
              विस्तार किया जा सके।
            </div>
          </div>
          <div
            data-aos="zoom-in"
            data-aos-once="true"
            data-aos-offset="200"
            className=" flex justify-center py-[5%] w-[55vw] rounded-[5px] items-center border-solid border-[#69564C] max-[560px]:hidden"
          >
            <img src={icon} alt="" className="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Mission;
