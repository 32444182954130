import React, { useEffect, useState } from "react";
import { FaSquarePhone } from "react-icons/fa6";
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa";
import { FaTelegram } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add event listener to update window width when the window is resized
    window.addEventListener("resize", handleResize);

    // Remove event listener when component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="bg-white">
      <div className="max-w-screen-xl px-4 py-12 mx-auto space-y-8 overflow-hidden sm:px-6 lg:px-8">
        <nav className="flex flex-wrap justify-center -mx-5 -my-2">
          <div className="px-5 py-2">
            {windowWidth > 800 && (
              <Link
                to="/ourinitiative"
                className="text-base leading-6 font-bold text-[#7AAB00] hover:text-[#677938]"
              >
                Our Initiatives
              </Link>
            )}
            {windowWidth <= 800 && (
              <a
                href="#ourinitiatives"
                className="text-base leading-6 font-bold text-[#7AAB00] hover:text-[#677938]"
              >
                Our Initiatives
              </a>
            )}
          </div>
          <div className="px-5 py-2">
            {windowWidth > 800 && (
              <Link
                to="aboutus"
                className="text-base leading-6 font-bold text-[#7AAB00] hover:text-[#677938]"
              >
                About Us
              </Link>
            )}
            {windowWidth <= 800 && (
              <a
                href="#aboutus"
                className="text-base leading-6 font-bold text-[#7AAB00] hover:text-[#677938]"
              >
                About Us
              </a>
            )}
          </div>
          <div className="px-5 py-2">
            {windowWidth > 800 && (
              <Link
                to="contactus"
                className="text-base leading-6 font-bold text-[#7AAB00] hover:text-[#677938]"
              >
                Contact Us
              </Link>
            )}
            {windowWidth <= 800 && (
              <a
                href="#contactus"
                className="text-base leading-6 font-bold text-[#7AAB00] hover:text-[#677938]"
              >
                Contact Us
              </a>
            )}
          </div>
          <div className="px-5 py-2">
            <Link
              to="https://docs.google.com/forms/d/e/1FAIpQLSfCo03snaTFTpUC6S3oi-L1nCKTVwoKGvTub4Ztv608Q0iBVQ/viewform?usp=sf_link"
              className="text-base leading-6 font-bold text-[#7AAB00] hover:text-[#677938]"
            >
              Apply Now
            </Link>
          </div>
        </nav>
        <div className="flex justify-center mt-10 flex-wrap gap-[20px] max-[520px]:gap-[30px]">
          <a
            href="tel:8059512356"
            target="_blank"
            rel="noreferrer"
            className="text-[#7AAB00] hover:text-[#677938]"
          >
            <span className="sr-only">Phone</span>
            <FaSquarePhone className="w-10 h-10" />
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=61557895950878"
            rel="noreferrer"
            target="_blank"
            className="text-[#7AAB00] hover:text-[#677938]"
          >
            <span className="sr-only">Facebook</span>
            <FaFacebookSquare className="w-10 h-10" />
          </a>
          <a
            href="https://www.instagram.com/pashusevakpragatikendra/"
            rel="noreferrer"
            target="_blank"
            className="text-[#7AAB00] hover:text-[#677938]"
          >
            <span className="sr-only">Instagram</span>
            <FaInstagram className="w-10 h-10" />
          </a>
          <a
            href="https://www.youtube.com/channel/UCoAOrXYmur6aLyQSxCtXo5Q"
            rel="noreferrer"
            target="_blank"
            className="text-[#7AAB00] hover:text-[#677938]"
          >
            <span className="sr-only">Youtube</span>
            <FaYoutube className="w-10 h-10" />
          </a>
          <a
            href="https://www.linkedin.com/company/101707590/admin/feed/posts/"
            rel="noreferrer"
            target="_blank"
            className="text-[#7AAB00] hover:text-[#677938]"
          >
            <span className="sr-only">LinkedIn</span>
            <FaLinkedin className="w-10 h-10" />
          </a>
          <a href="https://maps.app.goo.gl/8V7yA6m916V6CZCH6" className="text-[#7AAB00] hover:text-[#677938]">
            <span className="sr-only">Location</span>
            <FaLocationDot className="w-10 h-10" />
          </a>
          <a href="https://wa.me/918059512356" className="text-[#7AAB00] hover:text-[#677938]">
            <span className="sr-only">Whatsapp</span>
            <FaWhatsapp className="w-10 h-10" />
          </a>
          <a href="https://t.me/918059512356" className="text-[#7AAB00] hover:text-[#677938]">
            <span className="sr-only">Telegram</span>
            <FaTelegram className="w-10 h-10" />
          </a>
        </div>
        <p className="mt-8 text-base leading-6 text-center text-[#7AAB00]">
          © 2024 Herdsman Development Private Limited. All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
