import React, { useEffect } from "react";
import Video2 from "../components/video2/video2";


const Videos = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Video2/>
  );
};

export default Videos;