import React, { useState } from "react";
// import logo from "../../assets/headers/logo-2.png";
import { NavLink } from "react-router-dom";
import icon from "../../assets/headers/logo.webp";
import { FiMenu, FiX } from "react-icons/fi";
// <div className="border-2 border-solid rounded-[20px] p-[10px] flex w-max max-[800px]:w-[75%]">
//   <img
//     src={logo}
//     alt="logo"
//     className="w-[70px] h-[100%] max-[1024px]:ml-[20px] max-[840px]:ml-[10px]"
//   />
//   <div className="w-[5px] h-[100%] bg-yellow-400"></div>
//   <div className="ml-[5px] flex flex-col justify-center items-center py-[4px]">
//     <span className="font-bold text-[13px] text-[#75A018]">
//       पशुसेवक प्रगति केन्द्र
//     </span>
//     <span className="text-[#69564C] font-bold text-[13px]">
//       पशुपालक का सच्चा साथी
//     </span>
//   </div>
// </div>
const Headers = () => {
  const [open, setOpen] = useState(false);
  console.log(open);
  return (
    <div
      className={
        "z-10 border-b-4 border-solid border-[#69564C] sticky top-0 left-0 w-full h-[100px] p-[5px] flex justify-between bg-gray-100 max-[800px]:delay-[0.5s] " +
        `${
          open
            ? "max-[800px]:border-b-0 max-[800px]:sticky border-r-0 border-l-0"
            : "border-l-4 border-r-4"
        }`
      }
    >
      <img
        src={icon}
        alt=""
        className="ml-[40px] rounded-[10px] max-[1050px]:ml-[20px] max-[890px]:ml-[10px] max-[818px]:ml-[5px] max-[800px]:ml-[30px]"
      />
      <div
        className={
          "w-max  flex items-center justify-end mr-[40px] max-[1225px]:mr-[20px] max-[890px]:mr-[10px] max-[818px]:mr-[5px] max-[800px]:absolute max-[800px]:top-[100px] max-[800px]:w-full max-[800px]:text-center max-[800px]:transition-all max-[800px]:delay-[0.4s] max-[800px]:py-[40px] max-[800px]:border-[#69564C] max-[800px]:border-solid max-[800px]:border-b-4 " +
          `${
            open
              ? "max-[800px]:left-0 max-[800px]:bg-gray-100"
              : "max-[800px]:left-[-100%]"
          }`
        }
      >
        <ul className="text-[20px] max-[1225px]:text-[19px] max-[1125px]:text-[18px] max-[970px]:text-[17px] max-[915px]:text-[16px] max-[840px]:text-[15px] flex gap-[40px] justify-center items-center max-[1225px]:gap-[30px] max-[1125px]:gap-[20px] max-[1055px]:gap-[7px] max-[940px]:gap-[5px] max-[860px]:gap-[3px] max-[850px]:gap-[1px] max-[800px]:flex-col max-[800px]:w-full max-[800px]:gap-[20px] max-[800px]:text-[22px]">
          {open === false && (
            <NavLink to="/">
              <li className="p-[6px] px-[10px] rounded-[10px] cursor-pointer font-bold text-[#69564C] hover:bg-gray-300">
                Home
              </li>
            </NavLink>
          )}
          {open && (
            <a href="#home" onClick={() => setOpen(false)}>
              <li className="p-[6px] px-[10px] rounded-[10px] cursor-pointer font-bold text-[#69564C] hover:bg-gray-300">
                Home
              </li>
            </a>
          )}
          {open === false && (
            <NavLink to="/ourinitiative">
              <li className="p-[6px] px-[10px] rounded-[10px] cursor-pointer font-bold text-[#69564C] hover:bg-gray-300">
                Our Initiatives
              </li>
            </NavLink>
          )}
          {open && (
            <a href="#ourinitiatives" onClick={() => setOpen(false)}>
              <li className="p-[6px] px-[10px] rounded-[10px] cursor-pointer font-bold text-[#69564C] hover:bg-gray-300">
                Our Initiatives
              </li>
            </a>
          )}
          {open === false && (
            <NavLink to="/video">
              <li className="p-[6px] px-[10px] rounded-[10px] cursor-pointer font-bold text-[#69564C] hover:bg-gray-300">
                Videos
              </li>
            </NavLink>
          )}
          {open && (
            <a href="#video" onClick={() => setOpen(false)}>
              <li className="p-[6px] px-[10px] rounded-[10px] cursor-pointer font-bold text-[#69564C] hover:bg-gray-300">
                Videos
              </li>
            </a>
          )}
          {open === false && (
            <NavLink to="/aboutus">
              <li className="p-[6px] px-[10px] rounded-[10px] cursor-pointer font-bold text-[#69564C] hover:bg-gray-300">
                About Us
              </li>
            </NavLink>
          )}
          {open && (
            <a href="#aboutus" onClick={() => setOpen(false)}>
              <li className="p-[6px] px-[10px] rounded-[10px] cursor-pointer font-bold text-[#69564C] hover:bg-gray-300">
                About Us
              </li>
            </a>
          )}
          {open === false && (
            <NavLink to="/contactus">
              <li className="p-[6px] px-[10px] rounded-[10px] cursor-pointer font-bold text-[#69564C] hover:bg-gray-300">
                Contact Us
              </li>
            </NavLink>
          )}
          {open && (
            <a href="#contactus" onClick={() => setOpen(false)}>
              <li className="p-[6px] px-[10px] rounded-[10px] cursor-pointer font-bold text-[#69564C] hover:bg-gray-300">
                Contact Us
              </li>
            </a>
          )}
          <NavLink
            to="https://forms.gle/ZF82BkXi4mmUMsQa6"
            onClick={() => setOpen(false)}
          >
            <li className="p-[5px] px-[10px] rounded-[10px] cursor-pointer bg-[#69564C] hover:bg-[#FDDC59] text-[#FDDC59] hover:text-[#69564C] font-[600]">
              PashuSevak
              <br />
              Registration
            </li>
          </NavLink>
        </ul>
      </div>
      <div
        className="min-[800px]:hidden text-[40px] my-auto mr-[20px]"
        onClick={() => setOpen(!open)}
      >
        {open ? <FiX /> : <FiMenu />}
      </div>
    </div>
  );
};

export default Headers;
