import React from "react";
import founder from "../../assets/team/Founder.png";
import cofounder from "../../assets/team/cofounder.png";
import chiefopofficer from "../../assets/team/chiefoperatingofficer.jpg";
import chieftechnicalofficer from "../../assets/team/chieftechnicalofficer.png";
import advisor from "../../assets/team/Advisor.png";
const Team = () => {
  return (
    <>
      <div className=" w-full mt-[5%] mb-[4%] text-center text-[35px] text-[#FDDC59] max-[800px]:text-[30px] max-[680px]:text-[28px] max-[570px]:text-[23px] max-[460px]:text-[22px] max-[900px]:mt-[8%]">
        <span className="ring-2 ring-[#69564C] ring-offset-1 bg-[#69564C] rounded-[10px] p-[10px] px-[20px] font-[600]">
          हमारी टीम से मिलें
        </span>
      </div>
      <div className="flex justify-between mx-[10%] flex-wrap max-[1100px]:mx-[7%] max-[660px]:mx-auto max-[660px]:justify-center max-[660px]:gap-[40px] max-[760px]:mt-[7%] max-[660px]:w-[90%] max-[500px]:mt-[10%]">
        <div className="w-[25%] flex flex-col gap-[30px] justify-center items-center max-[1100px]:w-[30%] max-[880px]:w-[32%] max-[730px]:w-[35%] max-[660px]:w-[51%] max-[500px]:w-[60%] max-[400px]:w-[70%]">
          <img
            src={founder}
            alt=""
            className=" w-[80%] border-[3px] border-solid border-[#69564C] rounded-[16%]"
          />
          <div className="bg-[#a0c83a] py-[10px] px-[5px] w-full border-4 rounded-[20px] border-[#69564C] border-solid text-center ">
            {" "}
            <span className="text-[25px] font-bold text-[#69564C] max-[1300px]:text-[23px]">
              Sachin Aggrawal
            </span>
            <br />{" "}
            <span className="text-[20px] font-[600] text-[#f8ea4c]">
              Founder/CEO
            </span>
          </div>
        </div>
        <div className="w-[25%] flex flex-col gap-[30px] justify-center items-center max-[1100px]:w-[30%] max-[880px]:w-[32%] max-[730px]:w-[35%] max-[660px]:w-[51%] max-[500px]:w-[60%] max-[400px]:w-[70%]">
          <img
            src={cofounder}
            alt=""
            className="w-[80%] border-[3px] border-solid border-[#69564C] rounded-[16%]"
          />
          <div className="bg-[#a0c83a] py-[10px] px-[5px] w-full border-4 rounded-[20px] border-[#69564C] border-solid text-center">
            {" "}
            <span className="text-[25px] font-bold text-[#69564C] max-[1100px]:text-[23px]">
              Subhav Singh
            </span>
            <br />{" "}
            <span className="text-[20px] font-[600] text-[#f8ea4c]">
              Co-founder
            </span>
          </div>
        </div>
      </div>
      <div className="flex my-[5%]  mx-[10%] justify-between max-[1100px]:mx-[7%] flex-wrap max-[660px]:justify-center max-[660px]:gap-[40px] max-[660px]:mx-auto max-[660px]:w-[90%]">
        <div className="w-[25%] flex flex-col gap-[30px] justify-center items-center max-[1100px]:w-[30%] max-[690px]:w-[32%] max-[660px]:w-[51%] max-[500px]:w-[60%] max-[400px]:w-[70%]">
          <img
            src={chiefopofficer}
            alt=""
            className=" w-[80%] border-[4px] border-solid border-[#69564C] rounded-[16%]"
          />
          <div className="bg-[#a0c83a] py-[10px] w-[90%] px-[5px] border-4 rounded-[20px] border-[#69564C] border-solid text-center max-[850px]:w-full">
            {" "}
            <span className="text-[25px] font-bold text-[#69564C]">
              Mayank Garg
            </span>
            <br />{" "}
            <span className="text-[20px] font-[600] text-[#f8ea4c] max-[1300px]:text-[18px] max-[1175px]:text-[16px] max-[750px]:text-[15px]">
              Chief Operating Officer
            </span>
          </div>
        </div>
        <div className="w-[25%] flex flex-col gap-[30px] justify-center items-center max-[1100px]:w-[30%] max-[690px]:w-[32%] max-[660px]:w-[51%] max-[500px]:w-[60%] max-[400px]:w-[70%]">
          <img
            src={chieftechnicalofficer}
            alt=""
            className=" w-[80%] border-[3px] border-solid border-[#69564C] rounded-[16%]"
          />
          <div className="bg-[#a0c83a] py-[10px] w-[90%] px-[5px] border-4 rounded-[20px] border-[#69564C] border-solid text-center max-[850px]:w-full">
            {" "}
            <span className="text-[25px] font-bold text-[#69564C]">
              Chirag Garg
            </span>
            <br />{" "}
            <span className="text-[20px] font-[600] text-[#f8ea4c] max-[1300px]:text-[18px] max-[1175px]:text-[17px] max-[750px]:text-[15px]">
              Chief Technical Officer
            </span>
          </div>
        </div>
        <div className="w-[25%] flex flex-col gap-[30px] justify-center items-center max-[1100px]:w-[30%] max-[690px]:w-[32%] max-[660px]:w-[51%] max-[500px]:w-[60%] max-[400px]:w-[70%]">
          <img
            src={advisor}
            alt=""
            className=" w-[80%] border-[3px] border-solid border-[#69564C] rounded-[16%]"
          />
          <div className="bg-[#a0c83a] py-[10px] w-[90%] px-[5px] border-4 rounded-[20px] border-[#69564C] border-solid text-center max-[850px]:w-full">
            {" "}
            <span className="text-[25px] font-bold text-[#69564C]">
              Rahul Kumar
            </span>
            <br />{" "}
            <span className="text-[20px] font-[600] text-[#f8ea4c] max-[1300px]:text-[18px]">
              Honorable Advisor
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Team;
