import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "./video2.css";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Navigation, Autoplay } from "swiper/modules";



const useCustomCache = (apiUrl, ttl = 60000) => {
  const [data, setData] = useState(null);
  const [cache, setCache] = useState({});
  useEffect(() => {
    const cachedData = cache[apiUrl];
    if (cachedData && (Date.now() - cachedData.timestamp < ttl)) {
      setData(cachedData.data);
    } else {
      fetch(apiUrl)
        .then(response => response.json())
        .then(result => {
          setCache(prevCache => ({ ...prevCache, [apiUrl]: { data: result, timestamp: Date.now() } }));
          setData(result);
        });
    }
  }, [apiUrl, cache, ttl]);
  return data;
};


const Video2 = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [disableinteraction, setDisableInteraction] = useState(false);
  const cid = "UCoAOrXYmur6aLyQSxCtXo5Q";
  const channelURL = encodeURIComponent(`https://www.youtube.com/feeds/videos.xml?channel_id=${cid}`)
  const reqURL = `https://api.rss2json.com/v1/api.json?rss_url=${channelURL}`;
  const data = useCustomCache(reqURL);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add event listener to update window width when the window is resized
    window.addEventListener("resize", handleResize);

    // Remove event listener when component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect (() => {
    windowWidth <= 740
      ? setDisableInteraction(true)
      : setDisableInteraction(false);
  },[windowWidth]);
  useEffect (() => {
  },[]);


  
  // console.log(disableinteraction);
  return (
    <div id="video" className="relative max-[640px]:mb-[70px]">
      <Swiper
        slidesPerView={3}
        spaceBetween={0}
        navigation={true}
        autoplay={{
          delay: 2500,
          pauseOnMouseEnter: true,
          disableOnInteraction: disableinteraction,
        }}
        modules={[Navigation, Autoplay]}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          740: {
            slidesPerView: 2,
            spaceBetween: 0,
          },
          1125: {
            slidesPerView: 3,
            spaceBetween: 0,
          },
        }}
        className="mySwiper"
      >
        {
        !data ? (
         <p>Loading...</p>
        ) :
        (
            data['items'].map((item) => {
            let id = item.guid.split(":")[2]
            let link = "https://www.youtube.com/embed/"+id;

            return (
               <SwiperSlide>
               <div className="my-[50px] w-[90%] h-[300px] max-[1125px]:w-[85%]">
               <iframe title = {item.title} className="w-[100%] h-[100%]" src=  {link}>
                </iframe>
               </div>
             </SwiperSlide>
                  );
          }
          )
        )
      }
        <SwiperSlide>
                       <div className="my-[50px] w-[90%] h-[300px] max-[1125px]:w-[85%]">
                       <span class="h-full text-center flex justify-center items-center"> <a href="https://www.youtube.com/@PashuSevakPragatiKendra/videos"> पुराने वीडियो के लिए यहां क्लिक करें</a></span>
                       </div>
         </SwiperSlide>
        </Swiper>
    </div>
  );
};

export default Video2;
